import toastr from 'toastr'

import $ from 'jquery'

window.toastr = toastr

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  positionClass: 'toast-top-center',
  onclick: null,
  showDuration: '400',
  hideDuration: '1200',
  timeOut: '7000',
  extendedTimeOut: '5000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  toastClass: 'toastr'
}

$(document).on('turbo:before-cache', function () {
  toastr.clear()
})
