export const handleAjaxError = (xhr) => {
  if (xhr.status === '403') {
    // TODO: We should be collecting this notice from the payload
    toastr.warning('You are not authorized to perform this action.')
  } else {
    toastr.error(
      `Something when wrong while fetching data. <br/>Error code: ${xhr.status}`
    )
  }
  console.log(xhr)
}
