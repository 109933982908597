import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['editModeButton', 'showModeButton']

  connect () {
    this.editMode = false
  }

  toggleEditMode () {
    this.editMode = !this.editMode

    if (this.editMode) {
      this.enterEditMode()
    } else {
      this.quitEditMode()
    }
  }

  enterEditMode () {
    this.editModeButtonTargets.forEach((button) => {
      $(button).show()
    })

    this.showModeButtonTargets.forEach((button) => {
      $(button).hide()
    })
  }

  quitEditMode () {
    this.editModeButtonTargets.forEach((button) => {
      $(button).hide()
    })

    this.showModeButtonTargets.forEach((button) => {
      $(button).show()
    })
  }
}
