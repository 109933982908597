// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

import jquery from 'jquery'
import * as bootstrap from 'bootstrap'
import moment from 'moment'

import 'datatables.net-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons/js/buttons.html5.mjs'
import 'datatables.net-buttons/js/buttons.print.mjs'
import 'datatables.net-select-bs5'

import './modules/sidebar'
import './modules/theme'
import './modules/feather'
import './modules/toastr'
import './modules/uppy'
import '@fortawesome/fontawesome-free/js/all'

window.$ = jquery
window.jQuery = jquery
window.bootstrap = bootstrap
window.moment = moment
