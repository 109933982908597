import { Controller } from '@hotwired/stimulus'
import { Dashboard } from 'uppy'
import { uppyInstance, uploadedFileData } from '../modules/uppy'
import { nanoid } from 'nanoid'

export default class extends Controller {
  static targets = ['input']
  static values = { types: Array, server: String, cacheInput: String }

  connect () {
    console.log(this.cacheInputValue !== '')

    if (this.cacheInputValue !== '') {
      this.createFileInput(this.cacheInputValue)
    } else {
      this.uppy = this.createUppy()
    }
  }

  disconnect () {
    this.uppy.close()
  }

  createUppy () {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      server: this.serverValue
    })
      .use(Dashboard, {
        target: this.inputTarget.parentNode,
        inline: true,
        height: 300,
        replaceTargetContent: true
      })

    uppy.on('upload-success', (file, response) => {
      const fileValue = uploadedFileData(file, response, this.serverValue)

      this.createFileInput(fileValue)
    })

    return uppy
  }

  createFileInput (inputValue) {
    const hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = `order[attachments_attributes][${nanoid()}][file]`
    hiddenField.value = inputValue

    this.element.appendChild(hiddenField)
  }
}
