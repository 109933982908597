import { Controller } from '@hotwired/stimulus'
import { handleAjaxError } from '../handle_ajax_error'
import { defaultDatatableOptions } from '../modules/datatable'

export default class extends Controller {
  static targets = ['daterangeLabel']

  static values = {
    url: String
  }

  customer_columns = ['name', 'code', 'contact_person', 'email', 'phone', 'last_order_date']

  connect () {
    const tableEl = $(this.element).find('table')
    const opts = Object.assign({}, defaultDatatableOptions,
      {
        ajax: {
          url: this.urlValue,
          type: 'POST',
          data: (d) => {
            d.daterange = $('#date_range').val()
          }
        },
        columns: this.customer_columns.map((column) => {
          return { data: column }
        }),
        customer: [[5, 'desc']],
        error: handleAjaxError
      }
    )

    this.table = tableEl.DataTable(opts)
  }

  disconnect () {
    this.table.destroy()
  }

  reloadDatatable (d) {
    if (d.daterangeLabel) {
      this.daterangeLabelTarget.innerHTML = d.daterangeLabel
    }

    this.table.ajax.reload()
  }
}
