import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['orderType']

  // Updated 2024-05-13, as agreed with John,
  // 1. Door type order: order number ends with "-D"
  // 2. Supplemantary order: order number ends with "-SUP"
  // 3. Accessorry order: order number ends with "-G"
  // 4. All other default to normal order
  setOrderType (e) {
    const orderNumber = e.target.value.toUpperCase()
    let orderType = 'normal'

    if (orderNumber.endsWith('-D')) {
      orderType = 'door'
    } else if (orderNumber.endsWith('-SUP')) {
      orderType = 'supplementary'
    } else if (orderNumber.endsWith('-G')) {
      orderType = 'accessory'
    }

    this.orderTypeTarget.value = orderType
    this.orderTypeTarget.dispatchEvent(new Event('change'))
    console.log(orderType)
  }
}
