import { Controller } from '@hotwired/stimulus'
import select2 from 'select2'

export default class extends Controller {
  static values = {
    placeholder: String,
    includeBlank: { type: Boolean, default: true }
  }

  connect () {
    select2() // This is important to make sure select2 is loaded as a jQuery plugin

    $(this.element).select2({
      placeholder: this.placeholderValue,
      allowClear: this.includeBlankValue && !$(this.element).is('[readonly]'),
      closeOnSelect: !$(this.element).is('[multiple]'),
      dropdownParent: $(this.element).closest('.modal').length ? $(this.element).closest('.modal') : ''
    })

    $(this.element).on('select2:select select2:unselect', function (e) {
      const event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event)
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }
}
