import { Controller } from '@hotwired/stimulus'
import 'daterangepicker'

export default class extends Controller {
  static values = {
    format: { type: String, default: 'DD MMM YYYY' }
  }

  connect () {
    $(this.element).daterangepicker({
      linkedCalendars: false,
      startDate: moment('2023-12-23'),
      endDate: moment(),
      locale: {
        format: this.formatValue,
        cancelLabel: 'Clear'
      },
      cancelButtonClasses: 'btn-dark',
      ranges: {
        '23 Dec To Date': [moment('2023-12-23'), moment()],
        'Week to Date': [moment().isoWeekday(1), moment()],
        'Last Week': [moment().subtract(1, 'week').isoWeekday(1), moment().subtract(1, 'week').isoWeekday(7)],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        'All Time': [moment('2010-01-01'), moment()]
      },
      alwaysShowCalendars: true,
      opens: 'left'
    }, (_start, _end, label) => {
      this.updateInput($(this.element).data('daterangepicker').startDate.format(this.formatValue) + ' - ' + $(this.element).data('daterangepicker').endDate.format(this.formatValue))

      const event = new Event('daterangepicker.change', { bubbles: true })
      event.daterangeLabel = label

      this.element.dispatchEvent(event)
    })
  }

  disconnect () {
    // Disconnect the Handle Input Functions
    $(this.element).off(
      'apply.daterangepicker'
    ).off(
      'cancel.daterangepicker'
    )
  }

  updateInput (value) {
    $(this.element).val(value)
  }
}
