import { Controller } from '@hotwired/stimulus'
import { handleAjaxError } from '../handle_ajax_error'
import anime from 'animejs/lib/anime.es.js'
import { defaultDatatableOptions } from '../modules/datatable'

export default class extends Controller {
  static targets = ['daterangeLabel']

  static values = {
    url: String
  }

  order_columns = [
    { data: 'order_number' },
    { data: 'customer' },
    { data: 'job_name' },
    { data: 'price' },
    { data: 'order_date' },
    { data: 'arrival_date' },
    { data: 'pickup_date' },
    { data: 'attachment_included', sortable: false },
    { data: 'payment_status', sortable: false }
  ]

  connect () {
    const tableEl = $(this.element).find('table')
    const opts = Object.assign({}, defaultDatatableOptions,
      {
        ajax: {
          url: this.urlValue,
          type: 'POST',
          data: (d) => {
            d.customer_ids = $('#customer_ids').val()
            d.daterange = $('#date_range').val()
            d.order_types = $('#order_types').val()
            d.payment_status = $("input[type='radio'][name=payment_status]:checked").val()
          }
        },
        columns: this.order_columns,
        order: [[4, 'desc'], [1, 'desc']],
        error: handleAjaxError,
        drawCallback: (data) => {
          this.updateSummary(data.json)
          feather.replace()
        }
      }
    )

    this.table = tableEl.DataTable(opts)
  }

  disconnect () {
    this.table.destroy()
  }

  reloadDatatable (d) {
    if (d.daterangeLabel) {
      this.daterangeLabelTarget.innerHTML = d.daterangeLabel
    }

    this.table.ajax.reload()
  }

  updateSummary ({ totalOrders, totalValue, averageValue }) {
    this.updateSummaryItem('#total-orders', totalOrders)
    this.updateSummaryItem('#total-value', totalValue)
    this.updateSummaryItem('#average-value', averageValue)
  }

  updateSummaryItem (el, value) {
    // Return if value is the same
    if ($(el).html() === String(value)) {
      return
    }

    $(el).html('')

    // Stop current animation
    anime.remove(el)

    // Put it back
    anime.set(el, {
      opacity: 0,
      translateY: 12
    })

    $(el).html(value)

    anime({
      targets: el,
      translateY: 0,
      loop: false,
      easing: 'spring(1, 70, 20, 10)',
      opacity: 1,
      delay: () => {
        return anime.random(40, 160)
      }
    })
  }
}
