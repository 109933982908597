const buttonClass = 'btn-sm btn-light'

const defaultDatatableOptions = {
  serverSide: true,
  processing: true,
  stateSave: true,
  lengthMenu: [10, 100, 500],
  dom: '<"d-flex justify-content-between"lBf>rt<"d-flex justify-content-between"ip>',
  buttons: [
    [
      { extend: 'copy', className: buttonClass, text: '<i data-feather="copy"></i>' },
      { extend: 'csv', className: buttonClass, text: '<i data-feather="file-text"></i>' }
    ]
  ],
  responsive: true,
  stateSaveCallback: function (settings, data) {
    localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
  },
  stateLoadCallback: function (settings) {
    const states = JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))

    if (states === null) { return null }

    // clear the search
    states.search.search = ''

    return states
  }
}

export { defaultDatatableOptions }
